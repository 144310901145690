@import "./theme/app/variables.scss";

.introjs-tooltip.rtl {
  direction: rtl;
}

.introjs-tooltipbuttons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;

  a {
    background-color: $main-btn-clr;
    background-image: none;
    border-color: $main-btn-clr;
    box-shadow: none;
    color: #fff;
    cursor: pointer;
    text-decoration: none;
    text-shadow: none;
    transition: all $main-btn-transition-duration
      $main-btn-transition-timing-function;
    display: inline-block;
    float: none;
    margin: 0 auto;

    &:focus,
    &:hover {
      background-color: $main-btn-clr-hvr;
      background-image: none;
      border-color: $main-btn-clr-hvr;
      box-shadow: none;
      color: #fff;
      cursor: pointer;
      text-decoration: none;
      text-shadow: none;
    }

    &.introjs-disabled {
      background-color: $main-btn-clr-disabled;
      background-image: none;
      border-color: $main-btn-clr-disabled;
      box-shadow: none;
      color: #fff;
      cursor: not-allowed;
      text-decoration: none;
      text-shadow: none;

      &:not(.introjs-disabled):focus,
      &:not(.introjs-disabled):hover {
        background-color: $main-btn-clr-disabled;
        background-image: none;
        border-color: $main-btn-clr-disabled;
        box-shadow: none;
        color: $main-btn-clr-hvr;
        cursor: not-allowed;
        text-decoration: none;
        text-shadow: none;
      }
    }
  }
}

.introjs-button.introjs-disabled {
  background-color: $main-btn-clr-disabled;
  background-image: none;
  border-color: $main-btn-clr-disabled;
  box-shadow: none;
  color: #fff;
  cursor: not-allowed;
  text-decoration: none;
  text-shadow: none;

  &:focus,
  &:hover {
    background-color: $main-btn-clr-disabled;
    background-image: none;
    border-color: $main-btn-clr-disabled;
    box-shadow: none;
    color: $main-btn-clr-hvr;
    cursor: not-allowed;
    text-decoration: none;
    text-shadow: none;
  }
}

.introjs-tooltip {
  .introjs-tooltip-header {
    .introjs-skipbutton {
      background-color: transparent;
      background-image: none;
      border: none;
      box-shadow: none;
      color: $main-btn-clr;
      cursor: pointer;
      text-decoration: none;
      text-shadow: none;
      transition: all $main-btn-transition-duration
        $main-btn-transition-timing-function;
      font-size: 14px;
      top: 5px;
      right: 5px;
      line-height: unset;
      padding: 4px 6px;
      height: unset;

      &:focus,
      &:hover {
        background-color: transparent;
        background-image: none;
        box-shadow: none;
        color: $main-btn-clr-hvr;
        cursor: pointer;
        text-decoration: none;
        text-shadow: none;
      }
    }
  }

  &.rtl {
    .introjs-tooltip-header {
      .introjs-skipbutton {
        right: unset;
        left: 5px;
      }
    }
  }
}

.introjs-progressbar {
  background-color: $main-purple-clr;
}

.introjs-tooltiptext {
  color: $main-font-clr;
}

.introjs-dontShowAgain {
  display: flex;
  align-items: center;
  gap: 6px;

  #introjs-dontShowAgain {
    width: 16px;
    height: 16px;
    cursor: pointer;
    margin: 0;
  }

  label {
    margin: 0;
    font-size: 15px;
    font-weight: 500;
    color: $dark-grey-font-clr;
    cursor: pointer;
  }
}
