@import "./../variables";

.table-actions {
  a {
    cursor: pointer;
    color: $agio-green-blue;
    margin: 0 10px;
    i {
    }
  }
}
