/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on March 31, 2018 */

.sidebar-content {
  .text-muted {
    color: white;
    opacity: 0.7;
  }
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  color: $agio-grey-darkest;
}
// new
h3 {
  color: $agio-grey-darkest !important;
}

a {
  color: $agio-blue-light;
}

.navbar-default .navbar-nav > li > a,
.navbar-default .navbar-text {
  color: $agio-grey-darkest;
}

.navbar-default {
  //background: #ffff;
}

.onlyNavBar {
  .navbar-header {
    .navbar-brand {
      display: block !important;
      padding: 5px;
      img {
        height: 100%;
        width: auto;
      }
    }
  }
  .navbar-default {
    margin-left: 0 !important;
    padding-left: 15px;
    width: 100% !important;
  }
  .content {
    padding: 0 !important;
    .container-fluid {
      padding: 0 !important;
      //background-color: antiquewhite
    }
  }
}

body {
  font-family: $font-family-base;
}

a {
  &:hover,
  &:focus {
    text-decoration: none;
  }
}

.panel-heading {
  border-bottom: 1px solid $agio-grey-lighter !important;
}
.panel-footer {
  background-color: $agio-grey-lightest !important;
  border-top: 1px solid $agio-grey-lighter !important;
}

.btn-default {
  color: black !important;
}

.dl-horizontal {
  dt {
    width: 25%;
  }
  dd {
    margin: 0;
    width: 75%;
  }
}

.copyButton {
  color: $agio-blue-active;
  margin: 0 10px;
  cursor: pointer;
}

.color-red {
  color: $brand-danger;
}

.colored-block {
  &:before {
    width: 20px;
    height: 20px;
    display: inline-block;
    margin-right: 10px;
    vertical-align: middle;
    content: "";
    border-radius: 50%;
  }
  &--blueActive:before {
    background-color: $agio-blue-active;
  }
  &--greenBlue:before {
    background-color: $agio-green-blue;
  }
  &--pink:before {
    background-color: $agio-pink;
  }
}
