/********************************************************
					Sidebar visual
********************************************************/
//====================Functions==========================
@mixin active-link-no-accent() {
  color: #4766ff;
  background-color: #fff;
  text-decoration: none;
}

@mixin active-link($isRTL: "false") {
  color: #630bde !important;
  background-color: #ffff;
  font-size: 16px !important;
  text-decoration: none;

  //Inner border
  box-shadow:
    0px -1px 0px 0px $sidebar-border-color inset,
    0px 1px 0px 0px $sidebar-border-color inset;
  //Accent
  @if $isRTL != "false" {
    box-shadow:
      -3px 0 0 0 $sidebar-active-accent-color inset,
      0px -1px 0px 0px $sidebar-border-color inset,
      0px 1px 0px 0px $sidebar-border-color inset;
  } @else {
    box-shadow:
      3px 0 0 0 $sidebar-active-accent-color inset,
      0px -1px 0px 0px $sidebar-color inset,
      0px 1px 0px 0px transparent inset;
  }
}

@mixin active-link-side() {
  box-shadow: none;
  background: none;
}

//======================Logos============================
#application {
  .sidebar-content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .sidebar-content {
    button {
      width: 80%;
      margin-bottom: 1.5rem !important;
    }
  }

  .sidebar-logo {
    min-width: 100%;
    background: #ffff;
    border-bottom: 2px $agio-white solid;
    border-right: $sidebar-border;
    height: 100px;
    margin-bottom: 69px;

    > img {
      display: block;
      position: relative;
      top: 50%;
      transform: translateY(-50%);
      margin-left: 22px;
    }
    .logo-slim {
      display: none;
    }
  }

  &.sidebar-big-icons {
    .sidebar-logo {
    }
  }

  &.sidebar-slim {
    .sidebar-logo {
      height: $navbar-height;
      margin-bottom: 39px !important;
      .logo-default {
        display: none;
      }

      .logo-slim {
        display: block;
      }
    }
  }

  &.sidebar-full-height {
    .sidebar .sidebar-logo {
      min-width: 100%;
      display: block;
    }
    .navbar .navbar-brand {
      display: none;
    }
  }

  &:not(.sidebar-full-height) {
    .sidebar .sidebar-logo {
      display: none;
    }

    .navbar .navbar-brand {
      display: initial;
    }
  }
}

//=====================Avatar============================
.avatar-container {
  display: none;
}

#application.sidebar-big-icons.sidebar-with-avatar {
  .sidebar-logo {
    display: none;
  }

  .avatar-container {
    display: block;
  }
}

//==============Hidden Sidebar Head======================
.sidebar .sidebar-overlay-head {
  display: none;
  justify-content: space-between;
  align-items: center;
  height: $navbar-height + 1;
  border: $sidebar-border;
  border-width: 0 $sidebar-border-width $sidebar-border-width 0;
  padding: 0 25px 0 20px;

  .sidebar-switch {
    color: #615f72;
    // display: none;
    font-size: 18px;
  }
}

#application.sidebar-overlay .sidebar .sidebar-overlay-head {
  display: flex;
}

#application.sidebar-overlay .sidebar .sidebar-content {
  max-height: calc(100% - #{$navbar-height});
  position: relative;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

//=====================Default===========================
.navigation .sidebar {
  .sidebar-logo i {
    top: 20px;
    left: 186px;
    position: relative;
  }
  // ul.side-menu li:nth-child(5) {
  //   margin-top: 80px;
  // }
  background: none;
  ul.side-menu {
    min-width: 100%;
    list-style-type: none;
    background: #ffff;
    padding-left: 0;
    margin-bottom: 0;
    > li {
      border-bottom: none;
      box-sizing: border-box;

      a {
        i {
          margin-right: 10px;
          color: #cacad2;
          font-size: 26px;
        }
        a:nth-child(5) {
          margin-top: 80px;
        }
      }
      > a {
        display: block;
        width: 100%;
        padding: 15px 25px 15px 30px;
        color: #615f72;
        position: relative;
        > i:first-child {
          color: #cacad2;
        }
        .nav-label {
          font-size: $sidebar-font-size;
          // text-transform: uppercase;
          font-weight: 500;
          letter-spacing: 1px;
        }
        i:first-child {
          margin-right: 10px;
          width: 40px;
          text-align: center;
        }
        &:hover {
          @include active-link-no-accent();
          box-shadow:
            0px -1px 0px 0px #ffff inset,
            0px 1px 0px 0px transparent inset;

          > i:first-child {
            color: #630bde;
          }
          > i:last-child {
            color: #630bde;
          }
          span {
            color: #630bde !important;
          }
        }
        &:focus,
        &:active {
          text-decoration: none;
        }
      }
      &.has-icon {
        a {
          padding-left: 15px;
        }
      }
      &.active span {
        color: #630bde !important;
      }
      &.active > a {
        @include active-link();
        background-color: #e8f3fe;
        > i:first-child {
          color: #630bde;
        }
        > i:last-child {
          color: #630bde;
        }
      }

      //Set active if there is an active element in children
      //but the root is not expanded
      &.nested-active:not(.expanded) > a {
        @include active-link();
        background-color: #e8f3fe !important;
        > i:first-child {
          color: #4766ff;
        }
        span {
          color: black !important;
        }
      }
      /*border-bottom: $sidebar-border;*/
      border-right: $sidebar-border;
    }
  }
}
.rtl .navigation .sidebar {
  ul.side-menu > li {
    > a {
      direction: rtl;
      text-align: right;

      padding: 15px 20px 15px 25px;

      i:first-child {
        margin-right: 0;
        margin-left: 4px;
      }
    }

    &.active > a {
      @include active-link($isRTL: true);
    }

    &.nested-active:not(.expanded) > a {
      @include active-link($isRTL: true);
    }

    border-right: none;
    border-left: $sidebar-border;
  }

  .pull-right {
    float: left !important;
  }
}

//======================Big Icons========================
.sidebar-big-icons .navigation .sidebar {
  ul.side-menu > li {
    > a {
      > *:not(:first-child):not(.nav-label) {
        display: none;
      }
      text-align: center;
      padding-top: 20px;
      padding-bottom: 20px;

      > i:first-child {
        display: inline-block;
        width: 100%;
        font-size: 24px;
        margin-bottom: 10px;
        margin-right: 0;
      }
    }
  }
}

//=======================Slim============================
.sidebar-slim .navigation .sidebar {
  width: 68px !important;
  height: 100vh !important;
  text-align: center;
  align-items: center;
  h3 {
    display: none;
  }
  .sidebar-logo {
    margin-bottom: 96px;
    margin-left: -25px;
    margin-top: 15px;
  }
  .sidebar-logo i {
    left: 11px !important;
    top: 5px !important;
    position: relative;
    color: #615f72 !important;
  }
  ul.side-menu > li {
    > a {
      > *:not(:first-child) {
        display: none;
      }

      text-align: center;
      padding: 15px 10px;

      .nav-label {
        display: none;
      }

      i:first-child {
        margin-right: 0;
      }
    }
  }
}

/********************************************************
					Sub menus definition
********************************************************/
//=======================Default========================
.navigation .sidebar ul.side-menu {
  li {
    position: relative;

    //SubMenu container
    ul {
      transition: all 0.2s;
      display: block;
      list-style-type: none;
      padding-left: 0;
      li {
        &.active {
          background: #ffff;
          > a {
            @include active-link();
          }
        }
        a {
          position: relative;
          display: block;
          color: #615f72;
          padding: 15px 25px 15px 0;

          .nav-label {
            font-size: $sidebar-font-size;
            font-weight: 500;
            letter-spacing: 1px;
          }

          &:hover .nav-label {
            color: #630bde !important;
          }
          &:hover {
            @include active-link-no-accent();

            box-shadow:
              0px -1px 0px 0px #ffff inset,
              0px 1px 0px 0px transparent inset;

            > i {
              color: #630bde;
            }
          }

          &:focus,
          &:active {
            text-decoration: none;
          }
        }

        //Set active if there is an active element in children
        //but the root is not expanded
        &.nested-active:not(.expanded) > a {
          @include active-link();
        }
      }
      display: none;
      height: 0;
      overflow: hidden;

      &.velocity-animating {
        display: block;
      }

      transition: max-height 1s;
      -webkit-transition: max-height 1s;
    }
    &.expanded > ul {
      display: block;
      height: auto;
    }

    //SubMenu arrow
    > a > .arrow {
      position: absolute;
      right: 10px;
      // top: 50%;
      transform: translateY(-50%);
      &:before {
        content: "\f104";
      }
    }

    &.expanded {
      > a > .arrow {
        &:before {
          content: "\f107";
        }
      }
    }
  }
}

.rtl .navigation .sidebar ul.side-menu {
  li {
    > a > .arrow {
      right: auto;
      left: 13px;
      transform: rotateZ(0deg) !important;
    }

    ul li {
      a {
        padding: 15px 0 15px 25px;
        text-align: right;
      }

      &.active {
        > a:hover,
        > a {
          @include active-link($isRTL: true);
        }
      }

      &.nested-active:not(.expanded) > a {
        @include active-link($isRTL: true);
      }
    }
  }
}

//=============SubMenu specific levels definitions========
//					Default submenu
#application:not(.rtl) .navigation .sidebar ul.side-menu > li ul {
  &.submenu-level-1 {
    > li {
      margin-top: 0px !important;
    }
    > li > a {
      padding-left: 65px;
      i:first-child {
        width: 20px;
        color: #cacad2;
      }
    }
  }

  &.submenu-level-2 {
    > li > a {
      padding-left: 70px;
    }
  }

  &.submenu-level-3 {
    > li > a {
      padding-left: 70px;
    }
  }
}

#application.rtl .navigation .sidebar ul.side-menu > li ul {
  &.submenu-level-1 {
    > li > a {
      padding-right: 50px;
    }
  }

  &.submenu-level-2 {
    > li > a {
      padding-right: 60px;
    }
  }

  &.submenu-level-3 {
    > li > a {
      padding-right: 70px;
    }
  }
}

//					Side submenu
#application {
  &.sidebar-big-icons,
  &.sidebar-slim {
    .navigation .sidebar ul.side-menu > li ul {
      &.submenu-level-1 {
        > li > a {
          padding-left: 0;
        }
      }
      &.submenu-level-2 {
        > li > a {
          padding-left: 15px;
        }
      }
      &.submenu-level-3 {
        > li > a {
          padding-left: 30px;
        }
      }
    }
  }

  &.rtl.sidebar-big-icons,
  &.rtl.sidebar-slim {
    .navigation .sidebar ul.side-menu > li ul {
      &.submenu-level-1 {
        > li > a {
          padding-right: 0;
        }
      }
      &.submenu-level-2 {
        > li > a {
          padding-right: 15px;
        }
      }
      &.submenu-level-3 {
        > li > a {
          padding-right: 30px;
        }
      }
    }
  }
}

//======================Slim/Big icons=====================
#application {
  &.sidebar-big-icons,
  &.sidebar-slim {
    .navigation .sidebar ul.side-menu {
      > li {
        position: static;
        //Hide arrow
        > a {
          padding-left: 5px;
          padding-right: 5px;
          word-wrap: break-word;
        }
        //Outer submenu definition
        > ul {
          padding: 15px;
          min-width: $outer-submenu-width;
          position: absolute;
          left: 100%;
          top: 0;
          background: #ffff;
          min-height: 100%;
          z-index: 100;
          border-right: $sidebar-border;
          border-bottom: $sidebar-border;

          > li:first-child > a {
            padding-top: 15px;
          }

          li {
            > a {
              padding: 0 20px 0px 0;
              margin-bottom: 15px;
              &:hover {
                @include active-link-side();
              }

              .arrow {
                right: 3px;
              }
            }

            &.active > a {
              @include active-link-side();
            }

            &.nested-active:not(.expanded) > a {
              @include active-link-side();
            }
          }
        }
        //Display submenu on hover and change item border
        &:hover {
          > ul {
            display: block;
          }
          &:not(.nested-active):not(.active) {
            > a {
              @include active-link-no-accent();

              > i:first-child {
                color: #4766ff;
              }
            }
          }
        }
      }
    }
  }

  &.rtl.sidebar-big-icons,
  &.rtl.sidebar-slim {
    .navigation .sidebar ul.side-menu {
      > li {
        position: static;

        > ul {
          left: auto;
          right: 100%;
          border-right: none;
          border-left: $sidebar-border;

          li > a {
            padding: 0 0 0 20px;

            .arrow {
              right: auto;
              left: 3px;
            }
          }
        }
      }
    }
  }

  //Outer-submenu (BIG ICONS ONLY)
  &.sidebar-big-icons {
    .submenu-level-1 {
      -webkit-box-shadow: 5px 0px 21px -5px rgba(0, 0, 0, 0.6);
      -moz-box-shadow: 5px 0px 21px -5px rgba(0, 0, 0, 0.6);
      box-shadow: 5px 0px 21px -5px rgba(0, 0, 0, 0.08);

      z-index: 2;
    }

    &.sidebar-full-height {
      &:not(.navbar-disabled)
        .navigation
        .sidebar
        ul.side-menu
        > li
        > .submenu-level-1 {
        top: $navbar-height;
        min-height: calc(100% - #{$navbar-height});
      }

      &.sidebar-fixed:not(.navbar-fixed):not(.navbar-disabled)
        .affix
        .submenu-level-1 {
        top: 0 !important;
        min-height: 100% !important;
      }
    }
  }

  //Outer-submenu title (BIG ICONS ONLY)
  &.sidebar-big-icons .navigation .sidebar ul.side-menu > li > ul:before {
    content: attr(data-submenu-title);
    display: block;
    width: 100%;
    padding-bottom: 10px;
    border-bottom: $outer-submenu-title-line;
    font-size: $outer-submenu-title-size;
    color: $outer-submenu-title-color;
  }

  &.rtl.sidebar-big-icons .navigation .sidebar ul.side-menu > li > ul:before {
    text-align: right;
  }

  //Outer-submenu (SLIM ONLY)
  &.sidebar-slim .navigation .sidebar ul.side-menu > li {
    position: relative;

    .badge {
      display: none;
    }

    ul {
      position: absolute;
      left: 100%;
      height: auto;
      min-width: 200px;
      overflow: visible;
      background: #ffff;
      padding: 0;
      top: -$sidebar-border-width;

      border-top: $sidebar-border;
      border-bottom: $sidebar-border;
      border-right: none;

      > li {
        > a {
          margin: 0;
          padding: 10px 24px 10px 13px !important;

          > .arrow {
            right: 10px;

            transform: scaleX(-1) translateY(-50%);
          }
        }

        border-right: $sidebar-border;

        &:not(:first-child) {
          border-left: $sidebar-border;
        }

        &:hover {
          > ul {
            display: block;
          }

          &:not(.active) > a {
            @include active-link-no-accent();
          }
        }
      }
    }

    &.primary-submenu {
      > ul {
        top: 0;

        > li {
          border-left: none;
        }
      }
    }
  }

  &.rtl.sidebar-slim .navigation .sidebar ul.side-menu > li {
    position: relative;

    ul {
      left: auto;
      right: 100%;
      border-left: none;

      > li {
        > a {
          margin: 0;
          padding: 10px 13px 10px 24px !important;

          > .arrow {
            right: auto;
            left: 10px;
            transform: translateY(-50%);
          }
        }

        border-right: none;
        border-left: $sidebar-border;

        &:not(:first-child) {
          border-right: $sidebar-border;
        }
      }
    }
    &.primary-submenu {
      > ul > li {
        border-right: none;
        background-color: #ffff;
      }
    }
  }
}

/********************************************************
					Right sidebar
********************************************************/
#application {
  &:not(.navbar-fixed):not(.sidebar-overlay) {
    .right-sidebar.affix {
      height: 100%;
      top: 0;
    }
  }

  &.navbar-fixed .right-sidebar {
    position: fixed;
  }

  &.navbar-disabled .right-sidebar {
    top: 0;
    height: 100%;
  }

  &.boxed-layout {
    @media #{$large} {
      &:not(.navbar-fixed) .right-sidebar.affix-top {
        left: auto !important;
        right: 0 !important;
      }

      &.navbar-fixed .right-sidebar,
      .right-sidebar.affix {
        left: auto;
        right: 50%;
        transform: translateX($box-layout-width * 0.5);
      }

      .right-sidebar {
        display: none;
        margin-right: 0;

        &.active {
          display: block;
        }
      }
    }
  }

  &.sidebar-overlay {
    .right-sidebar {
      top: 0 !important;
      height: 100%;
      z-index: 1060;
    }
  }
}

.right-sidebar {
  width: $right-sidebar-width;
  /*padding-right: 20px;*/
  padding-left: 20px;
  position: absolute;
  overflow: hidden;
  top: $navbar-height;
  height: calc(100% - #{$navbar-height});
  right: 0;
  margin-right: -$right-sidebar-width;
  background-color: $sidebar-color;
  border-left: $sidebar-border;
  z-index: 99;
  //overflow-y: scroll;

  transition: margin 0.2s ease-out;

  &.affix {
    position: fixed;
  }

  &.active {
    margin-right: 0;
  }
}

#application.rtl .right-sidebar {
  right: auto;
  left: 0;

  margin-left: -$right-sidebar-width;
  margin-right: 0;

  &.active {
    margin-left: 0;
  }
}

.cmd-right-sidebar-toggle {
  &:focus i {
    color: $text-muted;
  }

  &:hover i {
    color: white;
  }

  &.active i {
    color: white;
  }
}

//RightSidebar Vertical scroll
.right-sidebar .vertical-scroll-container {
  position: relative;
  overflow-y: auto;
}

//RightSidebar Extra Content
.right-sidebar .right-sidebar-extra-content {
  position: absolute;
  width: 100%;
  left: 100%;

  padding-left: 0;
  //padding-right: 20px;

  background-color: $sidebar-color;
  transition: left 0.2s;

  z-index: 1200;
  &.active {
    left: 0;
  }
}

/********************************************************
					Additional classes
********************************************************/
.navigation .sidebar .sidebar-section {
  padding-left: 23px;
  padding-right: 25px;
}

.navigation .sidebar hr {
  border-top-color: $sidebar-border-color;
}

.sidebar-slim .sidebar-container-default,
.sidebar-big-icons .sidebar-container-default {
  display: none;
}

#application:not(.sidebar-big-icons) .sidebar-container-big-icons {
  display: none;
}

#application:not(.sidebar-full-height) .sidebar-container-slim,
#application:not(.sidebar-slim) .sidebar-container-slim {
  display: none;
}

.add-on-container {
  border-right: 1px solid $sidebar-border-color;
  border-bottom: 1px solid $gray-darker;
}

.sidebar-container-default {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.sidebar-container-big-icons {
  padding: 3rem 0;
  text-align: center;
}

.sidebar-container-slim {
  min-height: $navbar-height;
  position: relative;

  > .avatar {
    position: absolute !important;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.bar-details {
  @extend .flex-space-between;
  padding: 3px 0;
}
