.modal-backdrop.in {
  opacity: 0.5;
}

.modal {
  .modal-dialog {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%) !important;
    .modal-content {
      border: none;
      background: $agio-white;
      .modal-header {
        border: none;
      }
      .modal-body {
      }
      .modal-footer {
        border: none;
      }
    }
  }
}

.render-preview {
  .modal {
    .modal-dialog {
      position: absolute;
      top: 40%;
      left: 50%;
      transform: translate(-50%, -50%) !important;
      .modal-content {
        border: none;
        background: transparent;
        .modal-header {
          border: none;
        }
        .modal-body {
        }
        .modal-footer {
          border: none;
        }
      }
    }
  }
}
