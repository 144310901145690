@import "./../bootstrap/variables";

@import "./vendors/timezonePicker";
@import "./vendors/date-timepicker";

@import "variables";
@import "layout";
@import "sidebars";
@import "sidebar-styles";
@import "helpers";
@import "reset";
@import "./components/forms";
@import "./components/modal";
@import "./components/table";

.welcome-tour .swiper-pagination-bullets {
  position: absolute;
  width: 100%;
  bottom: 0;
  z-index: 1000000;
  span {
    width: 12px;
    height: 12px;
  }
}
