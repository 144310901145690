// Variables Overrides (For the list see: https://github.com/airbnb/react-dates/blob/master/css/variables.scss)
$react-dates-width-input: 130px !default;
$react-dates-width-arrow: 24px !default;
$react-dates-width-tooltip-arrow: 20px !default;
$react-dates-width-day-picker: 300px !default;
$react-dates-spacing-vertical-picker: 72px !default;

$react-dates-color-primary: $brand-primary;
$react-dates-color-primary-dark: $brand-primary;
$react-dates-color-primary-shade-1: $brand-primary;
$react-dates-color-primary-shade-2: #266c98;
$react-dates-color-primary-shade-3: $brand-primary;
$react-dates-color-primary-shade-4: #266c98;
$react-dates-color-secondary: #1a1a1a;
// $react-dates-color-white: #1A1A1A;
$react-dates-color-white: #f5f5f5;
// $react-dates-color-gray: white;
$react-dates-color-gray: #484848;
// $react-dates-color-gray-dark: white;
$react-dates-color-gray-dark: #1a1a1a;
$react-dates-color-gray-light: #5c5c58; // #82888a
$react-dates-color-gray-lighter: #404040; // #cacccd
$react-dates-color-highlighted: #ffe8bc !default;

$react-dates-color-border: #404040;
$react-dates-color-border-light: #404040;
$react-dates-color-border-medium: #404040;
$react-dates-color-placeholder-text: #757575 !default;
$react-dates-color-text: #484848 !default;
$react-dates-color-text-focus: white;
$react-dates-color-focus: $brand-primary;

@import "~react-dates/css/styles";

.DateRangePicker,
.SingleDatePicker {
  &__picker--portal,
  &__picker--full-screen-portal {
    z-index: 100;
  }
}

.DayPicker--portal {
  z-index: 100;
}

// .CalendarMonthGrid { background: #1A1A1A }
.CalendarMonthGrid {
  background: #f5f5f5;
}

.CalendarDay--selected,
.CalendarDay--hovered-span,
.CalendarDay--selected-start,
.CalendarDay--selected-span,
.CalendarDay--selected-end,
.CalendarDay--after-hovered-start,
.CalendarDay--hovered {
  color: #08c;
  background-color: white;
}

.CalendarDay[class="CalendarDay CalendarDay--valid CalendarDay--hovered"] {
  // border-style: solid;
}
/*
.CalendarDay--hovered {
    &:not(.CalendarDay--selected-start),
    &:not(.CalendarDay--hovered-span),
    &:not(.CalendarDay--selected-end) {
        border-style: solid;
    }
}
*/
// new style => restyle date range calender
.daterangepicker {
  border: 1px solid #ddd !important;
  .ranges {
    text-align: center !important;
    padding: 11px; //

    .active,
    li:hover {
      background-color: #08c !important;
    }
    ul {
      width: 100% !important;
    }
  }
}

.daterangepicker:after,
.daterangepicker:before {
  padding-left: 0 !important;
}

.daterangepicker .drp-calendar.left {
  margin-left: 0 !important;
}

//////////// responsive style

@media only screen and (max-width: 767px) {
  .daterangepicker {
    width: auto !important;
    margin-top: 7px !important;
  }
  .daterangepicker.ltr .drp-calendar,
  .daterangepicker.ltr .ranges {
    float: none !important;
  }

  // .daterangepicker.ltr .drp-calendar.left
  .daterangepicker.ltr {
    // top: 195px !important;
    top: 193px !important;

    // left: unset !important;
    // right: unset !important;
    // margin: 0 34% ;

    left: 0 !important;
    right: 0 !important;
    margin-left: auto !important;
    margin-right: auto !important;
    width: 279px !important;

    margin-bottom: 20px;
  }

  .daterangepicker.ltr .ranges {
    float: none !important;
  }

  .daterangepicker .ranges ul {
    padding-top: 17px !important;
  }

  .daterangepicker.opensright:before {
    // left: 120px !important;
    left: 133px !important;
  }
  .daterangepicker.opensright:after {
    // left: 121px !important;
    left: 134px !important;
  }

  .daterangepicker .drp-selected {
    display: block !important;
    margin-bottom: 10px;
  }
  .daterangepicker .drp-buttons {
    text-align: center !important;
  }
}

@media only screen and (max-width: 480px) {
  // .daterangepicker.ltr .drp-calendar.left
  .daterangepicker.ltr {
    // margin: 0 24%;
    margin-bottom: 20px; //
  }
}

@media only screen and (max-width: 370px) {
  // .daterangepicker.ltr .drp-calendar.left
  .daterangepicker.ltr {
    // margin: 0 11%;
    margin-bottom: 20px; //
  }
}

// new
@import "~@owczar/spin-template/dist/styles/plugins.min";

.daterangepicker td.available:hover,
.daterangepicker th.available:hover {
  background: #eee !important;
}

.daterangepicker td.in-range:hover {
  background-color: #ebf4f8 !important;
  color: #000 !important;
}
.daterangepicker td.start-date:hover,
.daterangepicker td.end-date:hover {
  background-color: #357ebd !important;
  color: #ffffff !important;
}

.ranges {
  li {
    background: #ffffff !important;
    border-radius: 18px !important;
    margin-top: 9px;
    margin-bottom: 0;
    width: 200px;
  }
}
//
.daterangepicker {
  background-color: #ffffff !important;
}

.daterangepicker:after {
  border-bottom: 6px solid #ffffff !important;
}

.daterangepicker:before {
  border-bottom: 7px solid #ccc !important;
}

.daterangepicker .calendar-table {
  border: 1px solid #fff !important;
  background-color: #fff !important;
}

.daterangepicker td.in-range {
  background-color: #ebf4f8 !important;
  color: #000 !important;
}

.daterangepicker td.end-date {
  background-color: #357ebd !important;
  border-color: transparent !important;
  color: #fff !important;
}

//
//////////// responsive style
@media only screen and (max-width: 767px) {
  .ranges {
    li {
      margin: 0 auto;
      margin-bottom: 8px !important; //
    }
  }
}

// new
@import "~@owczar/spin-template/dist/styles/bootstrap.min.css";
// start change profile buttoms hover color
.nav > li > a:hover {
  background-color: #eaeaea;
}
// end
.breadcrumb {
  background-color: #15151500 !important;
}

@media only screen and (min-width: 768px) {
  .dropdown-menu {
    background-color: #fff !important;
    border: 1px solid #ccc !important;
  }

  .dropdown-menu > li > a {
    color: #717171 !important;
  }
  .dropdown-menu > li > a:hover {
    color: #ffffff !important;
  }

  .dropdown-menu .divider {
    background-color: #989898 !important;
  }
}
.DateInput {
  background-color: #fff !important;
  // border: 1px solid #e3e4e5!important;
  height: 36px !important;
  padding: 2px !important;
}
.SingleDatePickerInput {
  border: 1px solid #e3e4e5 !important;
}
.SingleDatePicker__picker {
  left: 0px !important;
  top: 0px !important;
}
.DateRangePickerInput {
  display: flex !important;
  width: 254px !important;
  border: solid 1px #e3e4e5 !important;
}
.DateRangePickerInput__arrow {
  background-color: #e3e4e5 !important;
  padding: 5px !important;
}
.DateRangePicker__picker {
  position: relative;
  right: 0px !important;
}
.DateRangePicker__picker--open-down {
  top: 9px !important;
}
